<template> 
	<Login
		:roleProhibited="alert"
		 />
</template>

<script>
import Login from './Login.vue'
import { mapGetters } from "vuex";

export default {
	name: "Logout",
	components: {
		Login
	},
	data() {
		return {
			check1: null,
			check2: null,
			alert: ""
		}
	},
	computed: {
		...mapGetters({
			base_role: "auth/GET_USER_ROLE",
		}),
	},
	async mounted() {
		if(this.$route.query.token === 'expired') {
			this.alert = 'Время вашего сеанса истекло. Пожалуйста, войдите снова для продолжения работы.'
		}
		if(this.$route.query.role === 'declined') {
			this.alert = 'Ваша роль не подходит для использования сайта. Пожалуйста, воспользуйтесь приложением.'
		}
		if(this.base_role) {
			console.log('this.base_role', this.base_role)
			this.check1 = (await this.base_role.includes("employee"))
			this.check2 = (await this.base_role.includes("user"))
			if (this.check1 || this.check2) {
				this.$store.dispatch("auth/logout");
			} else {
				this.$store.dispatch("auth/logout");
				this.$router.push("/login");
			}
		} else {
			console.log('role', this.base_role)
		}
	}
}
</script>

<style></style>
